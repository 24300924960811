import { Button } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

const PopNarations = ({narrations={}, onSetNarrations, onNarrationsPopClose, uploadAsset}) => {

    const [narrs, setNarrs] = useState(narrations);

    const [availableLangs, setAvailableLangs] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/language`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setAvailableLangs(json.languages)
            });
    }, [])

    const onUploadNarration = (e, lang) => {
        uploadAsset(e, (fileName) => { setNarrs(prev => { return { ...prev, [lang]:fileName} }) })
        console.log(narrs);
    }

    const onRemoveNarration = (lang) => {
        setNarrs(prev => { return { ...prev, [lang]:""} });
    }

    return (
        <div className="pop-naration" id="pop-naration">
            <div className="pop-background"></div>
            <div className="pop-container">
                <div className="pop-header">
                    Upload Narations in other languages
                </div>
                <div className="pop-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {
                        availableLangs.map((l, index) =>
                            <Fragment key={index}>
                                <br />
                                <div className="flex flex-row">
                                    <div className="w-28">{l.language}</div>
                                    {(!narrs[l.language_slug] || narrs[l.language_slug].trim() === "") &&
                                        <>
                                        <Button
                                        variant="contained"
                                        component="label"
                                    >
                                        Upload Narration MP3
                                        <input
                                            type="file"
                                            hidden
                                            onChange={(e) => onUploadNarration(e, l.language_slug)}
                                        />
                                    </Button>
                                        </>
                                    }
                                    
                                    {narrs[l.language_slug] && narrs[l.language_slug].trim() !== "" &&
                                        <>
                                            <audio controls>
                                                <source src={``} type="audio/mpeg" />

                                            </audio>
                                            <Button
                                                onClick={() => onRemoveNarration(l.language_slug)}
                                            >
                                                Remove Narration

                                            </Button>
                                        </>
                                    }
                                </div>
                                <br />
                            </Fragment>
                        )
                    }
                </div>
                <div className="pop-footer flex flex-row">
                    <Button onClick={() => onSetNarrations(narrs)}>Update and Close</Button>
                    <Button onClick={onNarrationsPopClose}>Close</Button>
                </div>
            </div>
        </div>
    )
}

export default PopNarations;