import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { HiMiniLanguage } from "react-icons/hi2";

const Labels = () => {

    const [labels, setLabels] = useState([]);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL}/label`, { method: 'GET' })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                setLabels(json.labels);
            });

    }, []);

    return (
        <>
        <div className="content-header pb-6 flex justify-between">
                    <div className="content-header-label">Labels</div>
                    <div className="content-header-actions">
                    
                    </div>
                </div>
                <div className="content-body">

                    <table className="table-auto border w-full">
                        <thead>
                            <tr>
                                <th className="font-bold p-2 border-b text-left w-2">No</th>
                                <th className="font-bold p-2 border-b text-left w-full">Label</th>
                                <th className="font-bold py-2 px-4 border-b text-left">Update languages</th>
                            </tr>
                        </thead>
                        <tbody>

                            {labels.map((label, i) => <tr key={i}>
                                <td className="p-2 border-b text-left">{i + 1}</td>
                                <td className="p-2 border-b text-left">{label.label}</td>
                                <td className="py-2 px-4 border-b text-left"> <Link to={`/label/${label.label_id}`}><HiMiniLanguage className='l-icon' /></Link></td>
                            </tr>)}

                        </tbody>
                    </table>
                </div>
        </>
    )
}

export default Labels;