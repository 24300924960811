import { Button, TextField } from "@mui/material";
import "./../styles/PopLanguages.scss";
import { useEffect, useState } from "react";

const PopLanguages = ({ fieldName, languages, onSetLs, onLangPopClose }) => {
  
  const [langs, setLangs] = useState(languages);

  const [availableLangs, setAvailableLangs] = useState([]);



  const onChangeLang = (e, lang) => {
    setLangs(prevLangs => {
        return {...prevLangs, [lang]:e.target.value}
    })
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/language`, { method: 'GET' })
    .then((res) => res.json())
    .then((json) => {
        console.log(json);  
        setAvailableLangs(json.languages)      
    });
  }, [])

  return (
    <div open id="popup-languages">
      <div className="pop-background"></div>
      <div className="pop-container">
        <div className="pop-header">Input data in other languages</div>
        <div className="pop-body flex flex-col">
          {availableLangs.map((l) => <>
          <br />
          <TextField 
          label={l.language} 
          value={langs[l.language_slug] || ""} 
          onChange={(e) => { onChangeLang(e, l.language_slug)}}
          />
          <br />
          </>
          )}
          <br />
        </div>
        <div className="pop-footer">
          <Button
            variant="contained"
            className="!mr-6"
            onClick={() => onSetLs(langs)}
          >
            Update and Close
          </Button>
          <Button onClick={onLangPopClose}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

export default PopLanguages;
