import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Label = () => {

    const {label_id} = useParams();
    const [label, setLabel] = useState({});
    const [languages, setLanguages] = useState([]);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL}/label/${label_id}`, {method:'GET'})
        .then((res) => res.json())
        .then((json) => {
            console.log(json)
            setLabel(json);
        })


        fetch(`${process.env.REACT_APP_API_URL}/language`, {method:'GET'})
        .then((res) => res.json())
        .then((json) => {
            console.log(json)
            setLanguages(json.languages);
        })

    }, []);


    const onSaveLabel = () => {
        fetch(`${process.env.REACT_APP_API_URL}/label`, {
            method:'PUT',
            body:JSON.stringify(label),
            headers:{
                "Content-Type":"application/json"
            }
        })
        .then((res) => res.json())
        .then((json) => {
            console.log(json);
            alert(json.message);
        })
    }

    const onChanageLang = (e, lang) => {
        const lb = e.target.value;
        setLabel(prev => { return {...prev, label_ls:{...prev.label_ls, [lang]:lb}} })
    }

    return (
        <>
        <div className="content-header pb-6 flex justify-between">
                    <div className="content-header-label">Label Name: {label.label}</div>
                    <div className="content-header-actions">
                        <Button variant="contained" onClick={onSaveLabel}>Save Label</Button>
                    </div>
                </div>
                <div className="content-body">
                    {
                        languages.map((lang) => (
                            <div className="flex flex-row mb-3">
                                <TextField 
                                variant="outlined"
                                onChange={(e)=> onChanageLang(e, lang.language_slug)} 
                                value={label.label_ls && label.label_ls[lang.language_slug] || ''} 
                                label={lang.language} 
                                className="w-full"/>                                
                            </div>
                        ))
                    }
                </div>
        </>
    )
}

export default Label;