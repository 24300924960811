import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import space from "./../assets/space_1.jpg";
import { createPortal } from 'react-dom';

import * as THREE from 'three';

import "./../styles/PopAddEditHotspot.scss";
import { Button, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';

import FormLabel from '@mui/material/FormLabel';
import { useEffect, useState } from 'react';
import { ASSETS_PATH } from '../constants';
import { HiMiniLanguage } from "react-icons/hi2";
import PopLanguages from './PopLanguages';
import PopNarations from './PopNarations';

let isCanvas = false;
let gHotspot = {};
let gSpaceImage;

const PopAddEditHotspot = ({ hotspot, updateHotspot, onDone, spaces, spaceImage, uploadAsset }) => {


    const [hs, setHs] = useState({
        hotspot_name: "", hotspot_type: "none", hotspot_coords: {
            "x": 465,
            "y": -50,
            "z": 173
        }
    });


    const [languages, setLanguages] = useState(null);
    const [currentLangField, setCurrentLangField] = useState(null);
    const [narrations, setNarrations] = useState(null);



    useEffect(() => {
        setHs(hotspot);

        gHotspot = hotspot;
        gSpaceImage = spaceImage;
        //console.log(spaceImage)
        pointer = { ...hotspot.hotspot_coords };
        //console.log(pointer);
        if (!isCanvas) {
            // console.log(isCanvas);
            init();
            isCanvas = !isCanvas;
        }

    }, [])

    const onEnterHotspotName = (e) => {
        let s = e.target.value;
        setHs({ ...hs, hotspot_name: s });

    }

    const onChangeArrow = (e) => {

        const a = e.target.value;
        setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, arrow: a } });

    }

    useEffect(() => {

    }, [hs])


    const onChangeHType = (e) => {
        const type = e.target.value;



        setHs({ ...hs, hotspot_type: type });
    }

    const onUpdateClicked = (e) => {
        isCanvas = false;

        setHs({ ...hs, hotspot_coords: { ...hs.hotspot_coords, x: pointer.x, y: pointer.y, z: pointer.z } });
        gHotspot = hs;
        gHotspot.hotspot_coords = { ...pointer };

        updateHotspot(gHotspot);

    }

    const onDoneClicked = (e) => {
        isCanvas = false;
        onDone();

    }

    const onChangeSpaceLink = (e) => {
        const linkId = e.target.value;
        setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, hotspot_linked_to: linkId } });
    }

    const onEnterHotspotTitle = (e) => {
        const t = e.target.value;
        setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, title: t } })

    }

    const onEnterHotspotDescription = (e) => {
        const d = e.target.value;
        setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, description: d } })

    }

    const onUploadInfoImage = (e) => {
        const filename = uploadAsset(e, (fileName) => { setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, gallery: [fileName] } }) });
    }

    const onRemoveInfoImage = (e) => {
        setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, gallery: [] } })
    }

    const onUploadModel = (e) => {
        const filename = uploadAsset(e, (fileName) => { setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, artefact: { ...hs.hotspot_data.artefact, model_url: fileName } } }) });
    }
    const onRemoveModel = (e) => {
        setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, artefact: { ...hs.hotspot_data.artefact, model_url: "" } } });
    }

    const onUploadMap = (e) => {
        const filename = uploadAsset(e, (fileName) => { setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, artefact: { ...hs.hotspot_data.artefact, model_map_url: fileName } } }) });
    }
    const onRemoveMap = (e) => {
        setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, artefact: { ...hs.hotspot_data.artefact, model_map_url: "" } } });
    }

    const onUploadNarration = (e) => {
        const filename = uploadAsset(e, (fileName) => { setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, narration: fileName } }) });
    }

    const onRemoveNarration = (e) => {
        setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, narration: "" } })
    }



    const onSetNarrations = (narrations) => {
        setHs({ ...hs, hotspot_data: { ...hs.hotspot_data, narration_ls: {...narrations} } })
        setNarrations(null);
    }

    const onNarrationsPopClose = () => {
        setNarrations(null);
    }


    const onNarrPopup = () => {
        setNarrations({...hs.hotspot_data.narration_ls} || {});
    }

    const onLangPop = (field) => {
        setCurrentLangField(field)

        let ls = [];

        if (field === 'hotspot_name') {
            ls = hs.hotspot_name_ls || [];
        } else if (field === 'hotspot_title') {
            ls = hs.hotspot_data.title_ls || [];
        } else if (field === 'hotspot_desc') {
            ls = hs.hotspot_data.description_ls || [];
        }

        setLanguages(ls);
    }

    const onSetLs = (langs) => {

        if (currentLangField === 'hotspot_name') {
            setHs(prev => { return { ...prev, hotspot_name_ls: langs } })
        } else if (currentLangField === 'hotspot_title') {
            setHs(prev => { return { ...prev, hotspot_data: { ...hs.hotspot_data, title_ls: langs } } })
        } else if (currentLangField === 'hotspot_desc') {
            setHs(prev => { return { ...prev, hotspot_data: { ...hs.hotspot_data, description_ls: langs } } })
        }

        setCurrentLangField(null);
        setLanguages(null)
    }

    const onLangPopClose = () => {
        setLanguages(null)
    }

    return (
        <>
            <div className="pop-background"></div>
            <div className="pop-hsp-container">
                <div className="pop-header">

                </div>
                <div className='pop-body flex'>

                    <div className="pop-content" id="pano-container">

                    </div>
                    <div className="pop-details pl-4 flex flex-col">
                        <div className='flex flex-row'>
                            <TextField label='Hotspot Name' sx={{ width: 220 }} value={hs.hotspot_name} onChange={onEnterHotspotName} />
                            <IconButton aria-label="Add space name in other languages">
                                <HiMiniLanguage className='l-icon' onClick={() => onLangPop('hotspot_name')} />
                            </IconButton>
                        </div>

                        <br />
                        <hr />
                        <br />
                        <InputLabel id="demo-simple-select-label">Select Hotspot Type</InputLabel>
                        <br />
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={hs.hotspot_type}
                            label="Select Hotspot Type"
                            onChange={onChangeHType}
                            sx={{ width: 220 }}
                        >
                            <MenuItem value={'none'}>Select Hotspot Type</MenuItem>
                            <MenuItem value={'space'}>Space</MenuItem>
                            <MenuItem value={'info'}>Info</MenuItem>
                            <MenuItem value={'arrow'}>Arrow</MenuItem>
                            <MenuItem value={'artefact'}>Artefact</MenuItem>
                        </Select>



                        <br />
                        <hr />
                        <br />

                        {
                            hs.hotspot_type === 'space' &&
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={hs.hotspot_data.hotspot_linked_to}
                                label="Select Space"
                                onChange={onChangeSpaceLink}
                                sx={{ width: 220 }}
                            >
                                <MenuItem value={0}>Select Space</MenuItem>

                                {spaces.map(s => <MenuItem value={s.space_id}>{s.space_name}</MenuItem>)}

                            </Select>
                        }


                        {
                            hs.hotspot_type === 'info' &&

                            <>
                                <div className='flex flex-row'>
                                    <TextField label='Info Title' sx={{ width: 220 }} value={hs.hotspot_data.title} onChange={onEnterHotspotTitle} />
                                    <IconButton aria-label="Add space name in other languages">
                                        <HiMiniLanguage className='l-icon' onClick={() => onLangPop('hotspot_title')} />
                                    </IconButton>
                                </div>
                                <br />
                                <div className='flex flex-row'>
                                    <TextField label='Info Description' value={hs.hotspot_data.description} sx={{ width: 220 }} multiline rows={4} onChange={onEnterHotspotDescription} />
                                    <IconButton aria-label="Add space name in other languages">
                                        <HiMiniLanguage className='l-icon' onClick={() => onLangPop('hotspot_desc')} />
                                    </IconButton>
                                </div>
                                <br />
                                {
                                    hs.hotspot_data.narration && hs.hotspot_data.narration.trim() !== "" &&
                                    <>

                                        <audio controls>
                                            <source src={`${ASSETS_PATH}/${hs.hotspot_data.narration}`} type="audio/mpeg" />

                                        </audio>
                                        <br />
                                        <div className='flex flex-row'>
                                            <Button
                                                onClick={onRemoveNarration}
                                            >
                                                Remove Narration

                                            </Button>
                                            <IconButton aria-label="Add narration in other languages">
                                                <HiMiniLanguage className='l-icon' onClick={onNarrPopup} />
                                            </IconButton>
                                        </div>
                                    </>
                                }
                                {
                                    (!hs.hotspot_data.narration || hs.hotspot_data.narration.trim() === "") &&
                                    <>


                                        <Button
                                            variant="contained"
                                            component="label"
                                        >
                                            Upload Narration MP3
                                            <input
                                                type="file"
                                                hidden
                                                onChange={onUploadNarration}
                                            />
                                        </Button>
                                    </>
                                }
                                <br />
                                {
                                    hs.hotspot_data.gallery.length !== 0 &&
                                    <>
                                        <img src={`${ASSETS_PATH}/${hs.hotspot_data.gallery[0]}`} />
                                        <br />
                                        <Button
                                            onClick={onRemoveInfoImage}
                                        >
                                            Remove Info Image

                                        </Button>
                                    </>
                                }
                                {
                                    hs.hotspot_data.gallery.length === 0 &&
                                    <>


                                        <Button
                                            variant="contained"
                                            component="label"
                                        >
                                            Upload Info Image
                                            <input
                                                type="file"
                                                hidden
                                                onChange={onUploadInfoImage}
                                            />
                                        </Button>
                                    </>
                                }
                            </>
                        }

                        {
                            hs.hotspot_type === 'arrow' &&

                            <>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"

                                    value={hs.hotspot_data.arrow || 'left'}
                                    name="radio-buttons-group"
                                    onChange={onChangeArrow}
                                >
                                    <FormControlLabel value="left" control={<Radio />} label="Left" />
                                    <FormControlLabel value="right" control={<Radio />} label="Right" />
                                    <FormControlLabel value="up" control={<Radio />} label="Up" />
                                    <FormControlLabel value="down" control={<Radio />} label="Down" />
                                </RadioGroup>
                            </>
                        }

                        {
                            hs.hotspot_type === 'artefact' &&

                            <>
                                <div className='flex flex-row'>
                                    <TextField label='Artefact Title' sx={{ width: 220 }} value={hs.hotspot_data.title} onChange={onEnterHotspotTitle} />
                                    <IconButton aria-label="Add space name in other languages">
                                        <HiMiniLanguage className='l-icon' onClick={() => onLangPop('hotspot_title')} />
                                    </IconButton>
                                </div>
                                <br />
                                <div className='flex flex-row'>
                                    <TextField label='Artefact Description' value={hs.hotspot_data.description} sx={{ width: 220 }} multiline rows={4} onChange={onEnterHotspotDescription} />
                                    <IconButton aria-label="Add space name in other languages">
                                        <HiMiniLanguage className='l-icon' onClick={() => onLangPop('hotspot_desc')} />
                                    </IconButton>
                                </div>
                                <br />
                                {
                                    hs.hotspot_data.narration && hs.hotspot_data.narration.trim() !== "" &&
                                    <>

                                        <audio controls>
                                            <source src={`${ASSETS_PATH}/${hs.hotspot_data.narration}`} type="audio/mpeg" />

                                        </audio>
                                        <br />
                                        <div className='flex flex-row'>
                                            <Button
                                                onClick={onRemoveNarration}
                                            >
                                                Remove Narration

                                            </Button>
                                            <IconButton aria-label="Add narration in other languages">
                                                <HiMiniLanguage className='l-icon' onClick={onNarrPopup} />
                                            </IconButton>
                                        </div>
                                    </>
                                }
                                {
                                    (!hs.hotspot_data.narration || hs.hotspot_data.narration.trim() === "") &&
                                    <>


                                        <Button
                                            variant="contained"
                                            component="label"
                                        >
                                            Upload Narration MP3
                                            <input
                                                type="file"
                                                hidden
                                                onChange={onUploadNarration}
                                            />
                                        </Button>
                                    </>
                                }
                                <br />
                                {
                                    (hs.hotspot_data.artefact.model_url && hs.hotspot_data.artefact.model_url.trim() !== "") &&
                                    <>
                                        <div>{hs.hotspot_data.artefact.model_url}</div>
                                        <br />
                                        <Button
                                            onClick={onRemoveModel}
                                        >
                                            Remove Model

                                        </Button>
                                    </>
                                }
                                {
                                    hs.hotspot_data.artefact.model_url === "" &&
                                    <>


                                        <Button
                                            variant="contained"
                                            component="label"
                                        >
                                            Upload Model
                                            <input
                                                type="file"
                                                hidden
                                                onChange={onUploadModel}
                                            />
                                        </Button>
                                    </>
                                }
                                <br />
                                {
                                    (hs.hotspot_data.artefact.model_map_url && hs.hotspot_data.artefact.model_map_url.trim() !== "") &&
                                    <>
                                        <div>{hs.hotspot_data.artefact.model_map_url}</div>
                                        <br />
                                        <Button
                                            onClick={onRemoveMap}
                                        >
                                            Remove Map

                                        </Button>
                                    </>
                                }
                                {
                                    hs.hotspot_data.artefact.model_map_url === "" &&
                                    <>


                                        <Button
                                            variant="contained"
                                            component="label"
                                        >
                                            Upload Map
                                            <input
                                                type="file"
                                                hidden
                                                onChange={onUploadMap}
                                            />
                                        </Button>
                                    </>
                                }
                            </>
                        }
                        <br />
                        <hr />
                        <br />

                    </div>
                </div>
                <div className='pop-footer'>
                    <Button onClick={() => { hotspotCreation = true }}>Place Hotspot</Button>
                    <Button onClick={onUpdateClicked}>Update and Close</Button>
                    <Button onClick={onDoneClicked}>Close</Button>
                </div>
            </div>
            {languages && createPortal(<PopLanguages fieldName={`Space Name`} languages={languages} onSetLs={onSetLs} onLangPopClose={onLangPopClose} />, document.getElementById('modal'))}
            {narrations && createPortal(<PopNarations narrations={narrations} uploadAsset={uploadAsset} onSetNarrations={onSetNarrations} onNarrationsPopClose={onNarrationsPopClose}/>, document.getElementById('modal'))}
        </>
    )
}

export default PopAddEditHotspot;



//// Three JS

let camera, scene, renderer;

let isUserInteracting = false,
    onPointerDownMouseX = 0, onPointerDownMouseY = 0,
    lon = 0, onPointerDownLon = 0,
    lat = 0, onPointerDownLat = 0,
    phi = 0, theta = 0;
let container;
let hotspotCreation = false;
let raycaster = new THREE.Raycaster();
let helper;
let pointer = { x: 0, y: 0, z: 0 };
let mesh;


function init() {

    container = document.getElementById('pano-container');

    camera = new THREE.PerspectiveCamera(75, container.offsetWidth / container.offsetHeight, 1, 1100);

    scene = new THREE.Scene();

    const geometry = new THREE.SphereGeometry(500, 60, 40);
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale(- 1, 1, 1);
    //console.log(`${ASSETS_PATH}/${gHotspot.space_image}`);
    const texture = new THREE.TextureLoader().load(`${ASSETS_PATH}/${gSpaceImage}`);
    texture.colorSpace = THREE.SRGBColorSpace;
    const material = new THREE.MeshBasicMaterial({ map: texture });

    mesh = new THREE.Mesh(geometry, material);

    scene.add(mesh);



    // helper geometry
    const geometryHelper = new THREE.ConeGeometry(20, 100, 3);
    geometryHelper.translate(0, 0, 0);
    geometryHelper.rotateX(Math.PI / 2);
    helper = new THREE.Mesh(geometryHelper, new THREE.MeshNormalMaterial());
    scene.add(helper);

    helper.position.set(pointer.x, pointer.y, pointer.z);

    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(container.offsetWidth, container.offsetHeight);
    renderer.setAnimationLoop(animate);
    container.appendChild(renderer.domElement);

    container.style.touchAction = 'none';
    container.addEventListener('pointerdown', onPointerDown);

    // document.addEventListener('wheel', onDocumentMouseWheel);

    window.addEventListener('resize', onWindowResize);

}

function onWindowResize() {

    camera.aspect = container.offsetWidth / container.offsetHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(container.offsetWidth, container.offsetHeight);

}

function onPointerDown(event) {

    if (hotspotCreation) {
        onHotspotPlaced(event);
        hotspotCreation = false;
        return;
    }

    if (event.isPrimary === false) return;

    isUserInteracting = true;

    onPointerDownMouseX = event.clientX;
    onPointerDownMouseY = event.clientY;

    onPointerDownLon = lon;
    onPointerDownLat = lat;

    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);

}

function onPointerMove(event) {


    if (event.isPrimary === false) return;

    lon = (onPointerDownMouseX - event.clientX) * 0.1 + onPointerDownLon;
    lat = (event.clientY - onPointerDownMouseY) * 0.1 + onPointerDownLat;


}


function onHotspotPlaced(event) {
    // hotspot creation start

    const bound = container.getBoundingClientRect();
    pointer.x = ((event.clientX - bound.left) / renderer.domElement.clientWidth) * 2 - 1;
    pointer.y = - ((event.clientY - bound.top) / renderer.domElement.clientHeight) * 2 + 1;
    raycaster.setFromCamera(pointer, camera);

    // See if the ray from the camera into the world hits one of our meshes
    const intersects = raycaster.intersectObject(mesh);

    // Toggle rotation bool for meshes that we clicked
    if (intersects.length > 0) {
        helper.position.set(0, 0, 0);
        helper.lookAt(intersects[0].face.normal);
        helper.position.copy(intersects[0].point);
        pointer.x = helper.position.x;
        pointer.y = helper.position.y;
        pointer.z = helper.position.z;

    }

    // hotspot creation end
}


function onPointerUp(event) {

    if (event.isPrimary === false) return;

    isUserInteracting = false;

    document.removeEventListener('pointermove', onPointerMove);
    document.removeEventListener('pointerup', onPointerUp);

}

function onDocumentMouseWheel(event) {

    const fov = camera.fov + event.deltaY * 0.05;

    camera.fov = THREE.MathUtils.clamp(fov, 10, 75);

    camera.updateProjectionMatrix();

}

function animate() {

    /* if ( isUserInteracting === false ) {

        lon += 0.1;

    } */

    lat = Math.max(- 85, Math.min(85, lat));
    phi = THREE.MathUtils.degToRad(90 - lat);
    theta = THREE.MathUtils.degToRad(lon);

    const x = 500 * Math.sin(phi) * Math.cos(theta);
    const y = 500 * Math.cos(phi);
    const z = 500 * Math.sin(phi) * Math.sin(theta);

    camera.lookAt(x, y, z);

    renderer.render(scene, camera);

}